import axios from 'axios';

import { validateEmail, createElement } from '../utils';
import { utils } from '@dantoo/dantools-frontend';

export default class TicketForm {
  messageTimeouts = {};

  constructor(div) {
    this.div = div;
    this.fields = div.querySelectorAll('.ticket-field');

    this.submitBtn = div.querySelector('.ticket-send');
    this.submitBtnText = this.submitBtn.textContent;

    this.captcha = div.querySelector('.g-recaptcha');
    utils.captchaUtils.renderCaptcha(this.captcha, (id) => (this.captchaId = id));

    if (this.submitBtn) this.submitBtn.addEventListener('click', this.send);
  }

  displayMessage = (message, type, timeout = 3000) => {
    const messageDiv = this.div.querySelector(`.ticket-${type}-message`);
    if (!messageDiv) return;
    if (this.messageTimeouts[type]) clearTimeout(this.messageTimeouts[type]);
    messageDiv.innerHTML = `<p>${message}</p>`;
    messageDiv.style.display = '';
    if (timeout > 0)
      this.messageTimeouts[type] = setTimeout(() => {
        messageDiv.innerHTML = '';
        messageDiv.style.display = 'none';
      }, timeout);
  };

  showMissingError = (node) => {
    node.classList.add('form__input-container--error');
  };

  removeMissingError = (node) => {
    node.parentNode.classList.remove('form__input-container--error');
    node.removeEventListener('keyup', this.removeMissingError, true);
  };

  setLoading = (bool) => {
    this.submitBtn.innerHTML = bool
      ? '<div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div>'
      : this.submitBtnText;
  };

  showCompletedBox = () => {
    this.formNodes = {};
    this.formNodes.completedBox = createElement('div', 'form__completed-box');

    this.formNodes.text = createElement('p', 'form__completed-box-text');
    this.formNodes.text.textContent = 'Thank you for sending us a message! You will receive an email confirmation shortly.';

    this.formNodes.button = createElement('button', 'form__completed-box-button', 'btn', 'btn--small', 'btn--secondary');
    this.formNodes.button.addEventListener('click', this.hideCompletedBox);
    this.formNodes.button.textContent = 'Send Another';

    this.formNodes.completedBox.appendChild(this.formNodes.text);
    this.formNodes.completedBox.appendChild(this.formNodes.button);

    this.div.appendChild(this.formNodes.completedBox);
  };

  hideCompletedBox = () => {
    this.formNodes.completedBox.remove();

    this.captcha.style.display = '';

    this.fields.forEach((el, i) => {
      setTimeout(() => {
        el.parentNode.parentNode.classList.remove('form__element--fade-out');
      }, (i + 1) * 150);

      if (i === this.fields.length - 1)
        setTimeout(() => {
          this.submitBtn.parentNode.classList.remove('form__element--fade-out');
        }, (this.fields.length + 1) * 150);
    });
  };

  resetCaptcha = () => {
    if (this.captchaId !== undefined) grecaptcha.reset(this.captchaId);
  };

  getCaptchaToken = () => (this.captchaId !== undefined ? grecaptcha.getResponse(this.captchaId) : undefined);

  send = async () => {
    this.setLoading(true);

    const body = {};
    let missingFields = [];

    for (let field of this.fields) {
      if (!field.value) {
        if (!field.required) continue;
        missingFields.push(field);
        continue;
      }
      body[field.name] = field.value;
    }

    if (missingFields.length) {
      missingFields.forEach((el) => {
        el.addEventListener('keyup', () => this.removeMissingError(el));
        this.showMissingError(el.parentNode);
      });
      this.setLoading(false);
      return this.displayMessage(`Missing required field${missingFields.length > 1 ? 's' : ''}.`, 'error');
    }

    if (!validateEmail(body.email)) {
      const emailNode = Array.from(this.fields).find((node) => node.name === 'email');
      if (!emailNode) return;

      emailNode.addEventListener('keyup', () => this.removeMissingError(emailNode));
      this.showMissingError(emailNode.parentNode);

      this.setLoading(false);
      return this.displayMessage(`Provided email is invalid.`, 'error');
    }

    const captchaToken = this.getCaptchaToken();
    if (!captchaToken) {
      this.setLoading(false);
      return this.displayMessage(`You need to complete the 'I'm not a Robot' Captcha to send a contact request.`, 'error');
    }

    body.category = 'Business';
    body.website = 'dantoo.net';
    body.token = captchaToken;

    let res;
    try {
      this.resetCaptcha();
      res = await axios.post('https://api.dantoo.net/v1/support', body);
    } catch (e) {
      this.setLoading(false);
      return this.displayMessage(e.message, 'error');
    }

    this.setLoading(false);

    const reversedNodeList = [].slice.call(this.fields, 0).reverse();

    this.captcha.style.display = 'none';

    this.submitBtn.parentNode.classList.add('form__element--fade-out');
    reversedNodeList.forEach((el, i) => {
      el.value = '';
      setTimeout(() => {
        el.parentNode.parentNode.classList.add('form__element--fade-out');
      }, (i + 1) * 200);

      if (i === reversedNodeList.length - 1)
        setTimeout(() => {
          this.showCompletedBox();
        }, reversedNodeList.length * 200);
    });
  };
}
