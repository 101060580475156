export default class ThemeToggleButton {
  constructor(el) {
    this.button = el;
    this.state = localStorage.getItem('theme') || 'dark';
    this.logos = document.querySelectorAll('.theme-logo');

    this.button.addEventListener('click', this.toggleTheme);

    this.initTheme();
  }

  initTheme = () => {
    document.querySelector('body').classList.add(`${this.state}-theme`);
    this.changeTheme();
  };

  changeTheme = () => {
    this.logos.forEach((cur) => (cur.src = cur.dataset[this.state]));
  };

  toggleTheme = () => {
    document.querySelector('body').classList.remove(`${this.state}-theme`);
    this.state = this.state === 'dark' ? 'light' : 'dark';
    document.querySelector('body').classList.add(`${this.state}-theme`);

    localStorage.setItem('theme', this.state);
    setCookie('theme', this.state, 90);
    document.cookie = this.changeTheme();
  };
}

function setCookie(name, value, days) {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

function getCookie(name) {
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
