// import './sentry';
// Other imports below
import ThemeToggleButton from './components/ThemeToggleButton';
import Input from './components/Input';
import ResponsivePosition from './components/ResponsivePosition';
import TicketForm from './components/TicketForm';
import { utils } from '@dantoo/dantools-frontend';

window.captchaUtilsLoaded = utils.captchaUtils.captchaUtilsLoaded;

let themeButtons = document.querySelectorAll('.theme-toggle');
themeButtons.forEach((cur) => new ThemeToggleButton(cur));


let inputs = document.querySelectorAll('.form__input-container');
inputs.forEach((cur) => new Input(cur));

let responsiveElements = document.querySelectorAll('.responsive-position');
responsiveElements.forEach((cur) => new ResponsivePosition(cur));

let ticketForms = document.querySelectorAll('.ticket-form');
ticketForms.forEach((tf) => new TicketForm(tf));
