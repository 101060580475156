export default class Input {
  constructor(el) {
    this.container = el;
    this.input = el.querySelector('.form__input, .form__textarea');

    this.input.addEventListener('focus', this.focusInput);
    this.input.addEventListener('focusout', this.focusOutInput);
  }

  focusInput = (e) => {
    this.input.focus();
    this.container.classList.add('form__input-container--active');
  };

  focusOutInput = (e) => {
    if (!this.input.value) {
      this.container.classList.remove('form__input-container--active');
    }
  };
}
